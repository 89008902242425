<template>
  <div class="company-detail">
    <div class="table">
      <div class="t-head">
        <span style="width: 40%">影视企业</span>
        <span style="width: 30%">电影剧本(梗概)备案数</span>
        <span style="width: 25%">电影公映许可证数</span>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getList(name)"
        class="lists"
        :offset="20"
      >
        <ul class="t-body">
          <li
            v-for="(item, index) in tableList"
            :key="index"
            @click="goDetail(item.id)"
          >
            <div style="width: 50%">{{ item.name }}</div>
            <div style="width: 25%; text-align: center">
              <span class="num">{{ item.bagsNum }}</span>
            </div>
            <div style="width: 25%; text-align: center">
              <span class="num">{{ item.gyxkzNum }}</span>
            </div>
          </li>
        </ul>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      tableList: [],
      pageNo: 1,
      pageSize: 20,
      name: '',
    }
  },
  methods: {
    getList(name, isInit = false) {
      this.name = name ? name : ''
      if (isInit) {
        this.pageNo = 1
        this.tableList = []
        this.finished = false
      }
      this.$axios({
        apiUrl: '/ycc/query/companyAndBANum',
        url: 'mgop.wasu.zyt.ycc0query0companyAndBANum',
        method: 'GET',
        data: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          name: this.name,
        },
      }).then((res) => {
        res.result.records.forEach((item) => {
          this.tableList.push(item)
        })
        // 加载状态结束
        this.loading = false
        //页数+1
        this.pageNo++
        // 数据全部加载完成
        if (res.result.records.length < this.pageSize) {
          this.finished = true
        }
      })
    },
    goDetail(id) {
      this.$router.push({
        path: '/companyDetail',
        query: {
          id: id,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.company-detail {
  width: 100%;
  .table {
    margin-top: 10px;
    .t-head {
      padding: 0 12px;
      height: 41px;
      background: #ebf3fe;
      border-radius: 4px 4px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 13px;
        font-weight: 400;
        color: #686b73;
      }
    }
    .lists {
      //border: 1px solid red;
      height: calc(100vh - 160px);
      overflow-y: auto;
      .t-body {
        li {
          width: 100%;
          height: 47px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          color: #363a44;
          padding: 0 12px;
          background: #fff;
          box-sizing: border-box;
          .num {
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            background: #ebf3fe;
            border-radius: 2px;
            font-size: 14px;
            font-weight: 500;
            color: #428ffc;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
</style>
