<template>
  <div class="record">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getList(name)"
      class="lists"
    >
      <ul class="record-list">
        <li
          v-for="(item, index) in tableList"
          :key="index"
          @click="goDetail(item.id)"
        >
          <div class="top">
            <div class="name">
              <span class="movie-name">{{ item.filmName }}</span>
              <span class="record-name">{{ item.balxh }}</span>
            </div>
            <div
              class="dw"
              v-for="(dwitem, dwindex) in item.badw"
              :key="dwindex"
            >
              <i></i>
              <span>{{ dwitem }}</span>
            </div>
          </div>
          <div class="bottom">
            <div>
              <span>备案地:</span>
              <span>{{ item.bad }}</span>
            </div>
            <div>
              <span>公示时间:</span>
              <span v-if="item.gssj">{{ item.gssj.slice(0, 7) }}</span>
            </div>
          </div>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      tableList: [],
      pageNo: 1,
      pageSize: 20,
      name: '',
    }
  },
  methods: {
    getList(name, isInit = false) {
      if (isInit) {
        this.pageNo = 1
        this.tableList = []
        this.finished = false
      }
      this.name = name ? name : ''
      this.$axios({
        apiUrl: '/ycc/query/filmBags',
        url: 'mgop.wasu.zyt.ycc0query0filmBags',
        method: 'GET',
        data: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          filmName: name ? name : '',
        },
      }).then((res) => {
        res.result.records.forEach((item) => {
          item.badw = item.badw.split('、')
          this.tableList.push(item)
        })

        // 加载状态结束
        this.loading = false
        //页数+1
        this.pageNo++
        // 数据全部加载完成
        if (res.result.records.length < this.pageSize) {
          this.finished = true
        }
      })
    },
    goDetail(id) {
      this.$router.push({
        path: '/recordDetail',
        query: {
          id: id,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.record {
  width: 100%;
  .lists {
    height: calc(100vh - 110px);
    overflow-y: auto;
    margin-top: 10px;
  }
  .record-list {
    li {
      width: 100%;
      background: #ffffff;
      border-radius: @border-radius-base;
      margin-bottom: 12px;
      .top {
        padding: 12px;
        box-sizing: border-box;
        .name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;
          .movie-name {
            font-size: 16px;
            font-weight: 500;
            color: #363a44;
          }
          .record-name {
            font-size: 12px;
            font-weight: 400;
            color: #428ffc;
          }
        }
        .dw {
          i {
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            border: 1px solid #428ffc;
            margin-right: 5px;
          }
          span {
            font-size: 12px;
            font-weight: 400;
            color: #363a44;
          }
        }
      }
      .bottom {
        height: 40px;
        background: url('../../../assets/info/itembg.png') no-repeat;
        background-size: cover;
        border-radius: 0px 0px 4px 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        & > div {
          flex: 1;
          span {
            font-size: 12px;
            font-weight: 400;
            color: #686b73;
          }
        }
      }
    }
  }
}
</style>
